import { Injectable, OnInit } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ResponseService } from "../response/response.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SnackbarService } from "../snackbar/snackbar.service";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization:
          "Bearer " + JSON.parse(window.localStorage.getItem("currentUser")),
          //  'ngrok-skip-browser-warning': 'true'
      },
    });
    return next.handle(request);
  }
}

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptorService implements HttpInterceptor, OnInit {
  returnUrl: string;

  constructor(
    private response: ResponseService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackbarService
  ) {}

  ngOnInit() {
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {




    return next.handle(request).pipe(
      // retry(3),
      catchError((err) => {
        console.log(err)
        if (err.error.status === 401) {
          // auto logout if 401 response returned from api
          // this.authenticationService.logout();
          // location.reload(true);
          // this.response.handleError(err);
          const error = err.error.message || err.statusText;
          this.snackBar.openSnackBar('You have been logged out!!');
          window.localStorage.clear();
          this.router.navigate(['/login']);
        }
        // const error = err.error.message || err.statusText;
        // return throwError(error);
        return this.response.handleError(err);
      })
    );
  }
}
