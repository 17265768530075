import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocationService {
  // BATTUTA_KEY = '604b4439430dd7298516a169c0a6384a';
  // abc;

  constructor(
    private http: HttpClient
  ) { }

  public getStates(): Observable<any> {
    return this.http.get('../../../assets/location/states.json');
  }

  public getCountries(): Observable<any> {
    return this.http.get('../../../assets/location/countries.json');
  }

  public getCities(): Observable<any> {
    return this.http.get('../../../assets/location/cities.json');
  }

  // public updateState(data){
  //   return this.http.post('../../../assets/location/state.json',data);
  // }

  // battuta
  // public getcountry() {
  //   return this.http.get(`https://battuta.medunes.net/api/country/all/?key=${this.BATTUTA_KEY}`);
  // }

  // public async getState(countryCode) {
  //   return await this.http.get(`https://battuta.medunes.net/api/region/${countryCode}/all/?key=${this.BATTUTA_KEY}`);
  // }
  // public async getCity(countryCode, state) {
  //   return await this.http.get(`https://battuta.medunes.net/api/city/${countryCode}/search/?region=${state}&key=${this.BATTUTA_KEY}`);
  // }
}
