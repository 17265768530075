// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL: "/api",
  // API_URL: 'https://vinsoft.qnvert.com/api',
  // API_URL: 'http://localhost:8080/api',
  whatsappAppId: "8110279139005638",
  facebookAppId: "486657005676488",
};
// http://13.127.125.29:5000
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
//  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.