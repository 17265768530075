import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { pickBy, identity } from "lodash";
import { BehaviorSubject, of, EMPTY } from "rxjs";
import {
  map,
  finalize,
  switchMap,
  mergeMap,
  tap,
  shareReplay,
  takeLast,
} from "rxjs/operators";
import { ResponseService } from "../../utils/response/response.service";
import { SnackbarService } from "../../utils/snackbar/snackbar.service";
import { LoaderService } from "../../utils/loader/loader.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { LEAD_COUNT } from "../../store/actions/appActions";

@Injectable({
  providedIn: "root",
})
export class LeadService {
  loadingStatus: any = new Subject();
  loadingDropdownStatus: any = new Subject();
  // componentShow: any = new Subject();

  public searchLead$ = new BehaviorSubject<any>("");
  public leadData$ = new BehaviorSubject<any>("");
  public leadCount$ = new BehaviorSubject<any>("");
  public leadProfileData$ = new BehaviorSubject<any>("");
  public showFresh$ = new BehaviorSubject<any>("");
  public companyList$ = new BehaviorSubject<any>("");
  public companyListDropdown$ = new BehaviorSubject<any>("");
  public companyListCount$ = new BehaviorSubject<any>("");
  public dealLead = new BehaviorSubject<any>("");
  public pendingTaskData$ = new BehaviorSubject<any>("");
  public changeTab = new BehaviorSubject<any>("");
  public changeTaskTab = new BehaviorSubject<any>("");
  public messageDeal = new BehaviorSubject<any>("");

  public totalItemCount= new BehaviorSubject<any>("");

  // for on open deal activity , note, task change
  public dealActivityFor = new BehaviorSubject<any>("");
  // switching activity between deal and lead
  public activityPreference$ = new BehaviorSubject<any>("");

  // for lead activity for
  public leadActivityFor$ = new BehaviorSubject<any>("");

  // for deal create from lead profile
  public leadIdToSendCreateDeal$ = new BehaviorSubject<any>("");

  // for deal
  public allDeals$ = new BehaviorSubject<any>("");
  public allDealsCount$ = new BehaviorSubject<any>("");
  public dealData$ = new BehaviorSubject<any>("");
  public pendingForm = new BehaviorSubject<any>("");
  
  session = JSON.parse(localStorage.getItem("session"));

  constructor(
    private http: HttpClient,
    private response: ResponseService,
    private snackBar: SnackbarService,
    private loader: LoaderService,
    private router: Router,
    private store: Store<any>
  ) { }
  startLoading() {
    this.loadingStatus.next(true);
  }
  // showComponent() {
  //   this.componentShow.next(true);
  // }

  // hideComponent() {
  //   this.componentShow.next(false);
  // }

  stopLoading() {
    this.loadingStatus.next(false);
  }

  startDropdownLoading() {
    this.loadingDropdownStatus.next(true);
  }

  stopDropdownLoading() {
    this.loadingDropdownStatus.next(false);
  }

  public leadGlobalCount(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));

    this.http
      .get(`${environment.API_URL}/lead/dashboard`, { params: newParam })
      .pipe(
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      )
      .subscribe((res) => {
        console.log(res, 'dashboard count');
        this.store.dispatch({
          type: LEAD_COUNT,
          payload: res,
        });
        // this.leadCount$.next(res);
      });
  }

  leadData= []
  complete=false;
  // lead
  public getLeads(params?, dialog?, filterData?, filterMainData?) {
    if(!dialog){
      this.startLoading();
    }else{
      this.startDropdownLoading();
    }
    let newParam = params ? params : {};
    // newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/lead/getleads`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        tap(
          (val) => {
            // this.leadData$.next(val);
            //this.leadData.push(...val)
            this.leadData$.next(val);
            if(val.length<1){
              this.complete= true;
            }
            // Changes
            // if (dialog) {
            //   dialog.close({ val: 'filter', data: filterData, filterMainData: filterMainData });
            // }
          },
          (err) => (this.loader.loader = false)
        ),
        finalize(() => {this.stopLoading();this.stopDropdownLoading()})
      )
      .subscribe();
  }

  public getLeadsList(params?,dropdown?) {
    if(!dropdown){
      this.startLoading();
    }else{
      this.startDropdownLoading();
    }
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/lead/getleads`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        finalize(() => {this.stopLoading();this.stopDropdownLoading()})
      );
  }

  public getSearchLeads(data) {
    this.startLoading();
    let newParam = data ? data : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/lead/getleads`, { params: newParam })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res["data"]),
        finalize(() => this.stopLoading())
      );
  }

  public getSalesPerson() {
    this.startLoading();
    return this.http.get(`${environment.API_URL}/user/sales-person/list`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.stopLoading())
    );
  }

  public editLead(id, data, lid?) {
    this.startLoading();
    return this.http
      .put(`${environment.API_URL}/lead/${id}/edit`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            if (lid) {
              this.leadProfile(lid);
            }
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }
  public editLeadOnAdd(id, data, lid?) {
    this.startLoading();
    return this.http
      .put(`${environment.API_URL}/lead/${id}/edit`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            if (lid) {
              this.leadProfile(lid);
            }
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }
  public leadClosedWithAllDealsClosed(id, data, lid?) {
    this.startLoading();
    return this.http
      .put(`${environment.API_URL}/lead/${id}/edit`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            // this.snackBar.openSnackBar(res["message"]);
            if (lid) {
              this.leadProfile(lid);
            }
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public deleteLead(data, lid?) {
    this.startLoading();
    return this.http.delete(`${environment.API_URL}/lead/${data}`).pipe(
      map((res) => res),
      tap(
        (res) => {
          this.snackBar.openSnackBar(res["message"]);
          // this.leadProfile(lid);
        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => (this.loader.loader = false))
    );
  }

  public bulkAssign(data) {
    this.startLoading();
    return this.http
      .post(`${environment.API_URL}/lead/assign-salesPerson`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public addEmail(id, data) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/edit/email`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public changeSalesPerson(id, data) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/edit/counselor`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public addPhone(id, data) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/edit/contactNumber`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  // mail Send

  public sendMail(id, data) {
    this.startLoading();
    return this.http
      .post(`${environment.API_URL}/lead/${id}/schedule-mail`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public sendMessage(data, id) {
    this.startLoading();
    return this.http
      .post(`${environment.API_URL}/lead/${id}/sendSMS`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            if (data.deal) {
              this.dealProfile(data.deal);
            }
          },
          (err) => console.log(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public leadProfile(lId) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/getleads/${lId}`)
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        tap((res) => this.leadProfileData$.next(res)),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public getSingleLead(id: string) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/${id}/single`)
      .pipe(
        switchMap((res: any) => {
          return this.http.get(`${environment.API_URL}/lead/getleads/${res.data.lid}`);
        }),
        map((res: any) => {
          return res;
        }),
        finalize(() => {
          this.stopLoading();
        })
      );
  }
  

  public getLeadTimeline(lId) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/${lId}/timeline`)
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      )
  }

  public getLeadDetails(lId) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/getleads/${lId}`)
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        tap((res) => this.leadProfileData$.next(res)),
        finalize(() => this.stopLoading())
      )
  }

  // for load profile data brfore navigation to confirm
  // public leadProfileResolve(lId) {
  //   this.loader.loader = true;
  //   return this.http.get(`${environment.API_URL}/lead/getleads/${lId}`).pipe(
  //     shareReplay(),
  //     map(this.response.extractData),
  //     finalize(() => this.loader.loader = false),
  //   );
  // }

  public addLead(data) {
    this.startLoading();
    data.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .post(`${environment.API_URL}/lead/create`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  // company
  public addCompany(data) {
    this.startLoading();
    return this.http.post(`${environment.API_URL}/lead/create`, data).pipe(
      map((res) => res),
      tap(
        (res) => {
          this.snackBar.openSnackBar(res["message"]);
          this.getCompanies({ type: "company" });
        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.stopLoading())
    );
  }

  public getCompanies(param?) {
    this.startLoading();
    this.http
      .get(`${environment.API_URL}/lead/getleads`, { params: param })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      )
      .subscribe((res) => {
        this.companyList$.next(res)
      });
  }

  companyList= [];
  companyListComplete: boolean= false;
  public getCompaniesForDropdown(param?) {
    this.startDropdownLoading();
    this.http
      .get(`${environment.API_URL}/lead/getleads`, { params: param })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.stopDropdownLoading())
      )
      .subscribe((res) => {
        this.companyList.push(...res)
        if(res.length<1){
          this.companyListComplete= true;
        }
        this.companyListDropdown$.next(this.companyList)
      });
  }


  getCompanyCount(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    this.http
      .get(`${environment.API_URL}/lead/getleadsCount`, { params: newParam })
      .pipe(
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      )
      .subscribe((res) => {
        this.companyListCount$.next(res)
      });
  }

  public editCompany(id, data) {
    this.startLoading();
    return this.http.put(`${environment.API_URL}/lead/${id}/edit`, data).pipe(
      map((res) => res),
      tap(
        (res) => {
          this.snackBar.openSnackBar(res["message"]);
          this.getCompanies({ type: "company" });
        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.stopLoading())
    );
  }

  // // notes
  public addNote(id, data, leadNumberid?) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/add/note`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.leadProfile(leadNumberid);
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public addDealNote(did, data) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/deal/${did}/add/note`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.dealProfile(did);
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public getTimeline(did) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/deal/${did}/timeline`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      );
  }

  public deleteNote(lid, noteId) {
    this.startLoading();
    return this.http
      .delete(`${environment.API_URL}/lead/${lid}/delete/note/${noteId}`)
      .pipe(
        map(this.response.extractData),
        tap(
          (res) =>
            this.snackBar.openSnackBar(res["message"] ? res["message"] : ""),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public deleteDealNote(dealId, noteId) {
    this.startLoading();
    return this.http
      .delete(`${environment.API_URL}/deal/${dealId}/delete/note/${noteId}`)
      .pipe(
        map(this.response.extractData),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"] ? res["message"] : "");
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  // // interactions

  public addInteraction(id, data, leadNumberId?) {
    this.startLoading();
    let newData = data.deal
      ? {
        deal: data.deal,
        note: data.note,
        rating: data.rating,
        medium: data.medium,
        outcome: data.outcome,
        pipeline: data.pipeline,
        nextPipeline: data.nextPipeline,
      }
      : {
        note: data.note,
        rating: data.rating,
        medium: data.medium,
        outcome: data.outcome,
      };
    console.log(data);
    return this.http
      .post(
        `${environment.API_URL}/interaction/lead/${id}/interaction/create`,
        pickBy(newData, identity)
      )
      .pipe(
        map((res) => res),
        switchMap((res) => {
          return res["status"] === 200 && data.deal
            ? this.editParellelDeal(
              data.deal,
              pickBy(
                {
                  pipeline: data.pipeline,
                  nextPipeline: data.closed ? "" : data.nextPipeline,
                  closed: data.closed ? data.closed : false,
                  closeNote: data.closeNote ? data.closeNote : "",
                  products: data.products ? data.products : "",
                },
                identity
              )
            ).pipe(
              map((resp) => resp),
              mergeMap((response) => of(res))
            )
            : of({
              message: res["message"],
            });
        }),
        tap(
          (res) => {
            if (leadNumberId && data.deal === undefined) {
              this.leadProfile(leadNumberId);
            } else if (data.deal) {
              this.dealProfile(data.deal);
            }
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public getInteractionData() {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/interaction/interactionData`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      );
  }

  public getInteractionHistiry(interactionId) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/interaction/${interactionId}/history`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      );
  }

  public deleteInteraction(id, interactionId) {
    this.startLoading();
    return this.http
      .delete(
        `${environment.API_URL}/interaction/lead/${id}/interaction/${interactionId}/delete`
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public editInteraction(id, interactionId, data, leadNumberId?) {
    this.startLoading();
    let newData = data.deal
      ? {
        deal: data.deal,
        note: data.note,
        rating: data.rating,
        medium: data.medium,
        outcome: data.outcome,
      }
      : {
        note: data.note,
        rating: data.rating,
        medium: data.medium,
        outcome: data.outcome,
      };
    return this.http
      .put(
        `${environment.API_URL}/interaction/lead/${id}/interaction/${interactionId}/edit`,
        newData
      )
      .pipe(
        map((res) => res),
        switchMap((res) => {
          return res["status"] === 200 && data.deal
            ? this.editParellelDeal(data.deal, {
              stageNumber: data.stageNumber,
            }).pipe(
              map((resp) => resp),
              mergeMap((response) => of(res))
            )
            : of({
              message: res["message"],
            });
        }),
        tap(
          (res) => {
            if (leadNumberId && data.deal === undefined) {
              this.leadProfile(leadNumberId);
            } else if (data.deal) {
              this.dealProfile(data.deal);
            }
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }
  public editParellelDeal(dealId, data) {
    console.log('=================================')
    console.log(dealId, data)
    console.log('=================================')

    this.startLoading();
    return this.http
      .patch(
        `${environment.API_URL}/deal/${dealId}/edit`,
        pickBy(data, identity)
      )
      .pipe(
        map((res) => res),
        finalize(() => this.stopLoading())
      );
  }

  public addPendingTask(forPage, status, id, data, leadNumberId?) {
    console.log(forPage, status, data);
    this.startLoading();
    data.session = this.session;
    return this.http
      .post(
        `${environment.API_URL}/pendingtask/lead/${id}/pendingTask/create`,
        data
      )
      .pipe(
        map((res) => res),
        switchMap((res) => {
          return res["status"] === 200 && data.emailDate
            ? this.userEmail({ schedule: data.emailDate }).pipe(
              map((resp) => resp),
              mergeMap((response) => of(res))
            )
            : of({
              message: res["message"],
            });
        }),
        tap(
          (res) => {
            console.log(res);
            if (forPage == "taskPage") {
              // let date = new Date();
              // if (moment(date).isSame(data.pendingTaskDate, 'day')) {
              //   status = 'today';
              // }
              // else {
              //   status = 'upcomming';
              // }
              this.changeTaskTab.next(status);

              this.checkPage(data, forPage, status);
            } else {
              if (leadNumberId && data.deal === undefined) {
                this.leadProfile(leadNumberId);
              } else if (data.deal) {
                this.dealProfile(data.deal);
              }
            }
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public userEmail(data) {
    this.startLoading();
    return this.http
      .post(`${environment.API_URL}/user/scheduleMail`, data)
      .pipe(
        map((res) => res),
        finalize(() => this.stopLoading())
      );
  }

  // public getPendingTask() {
  //   return this.http.get(`${environment.API_URL}/pendingTask/list/all`).pipe(
  //     shareReplay(),
  //     map(res => res),
  //   );
  // }

  public getTodayPendingTask(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/pendingTask/list/all`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.stopLoading())
      );
  }

  public getTodayPendingTaskAuto(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = this.session;
    this.http
      .get(`${environment.API_URL}/pendingTask/list/all`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        map((res) => res),
        // map((res) => res["data"]),
        finalize(() => this.stopLoading())
      )
      .subscribe(
        (res) => {
          this.totalItemCount.next(res["message"]["All Pending Tasks."])
          this.pendingTaskData$.next(res["data"]);
        },
        (err) => { }
      );
  }

  public pendingTaskComplete(forPage, status, lid, data, id) {
    console.log(data, lid, forPage, status);
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/pendingTask/${id}/complete`, "")
      .pipe(
        tap(
          (res) => {
            if (forPage == "taskPage") {
              this.checkPage(data, forPage, status);
            } else {
              console.log(data);
              if (data.lead) {
                this.leadProfile(data.lead.lid ? data.lead.lid : lid);
              } else if (data.deal) {
                this.dealProfile(data.deal["_id"]);
              }
            }
            this.snackBar.openSnackBar(res["message"]);
            this.stopLoading();
          },
          (err) => {
            this.stopLoading();
            this.snackBar.openSnackBar(err);
          }
        )
      );
  }

  public getOldPendingTask(leadId) {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/pendingtask/lead/${leadId}/view-old-tasks`)
      .pipe(
        shareReplay(),
        map((res) => res),
        finalize(() => this.stopLoading())
      );
  }

  public deletePendingTask(
    task,
    id,
    pendingTaskId,
    forPage,
    status,
    dealId?,
    leadNumberId?
  ) {
    console.log(task, id, pendingTaskId, forPage, status, dealId, leadNumberId);
    this.startLoading();
    return this.http
      .delete(
        `${environment.API_URL}/pendingtask/lead/${id}/pendingTask/${pendingTaskId}/delete`
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            if (forPage == "taskPage") {
              this.checkPage(task, forPage, status);
            } else {
              if (leadNumberId) {
                this.leadProfile(leadNumberId);
              } else if (dealId) {
                this.dealProfile(dealId);
              }
            }
            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  checkPage(task, forPage, taskStatus) {
    console.log(taskStatus)
    this.startLoading();
    if (forPage == "taskPage") {
      if (taskStatus == "pending") {
        let onloadParam = {
          // taskDateFrom: moment.utc(new Date(new Date().setHours(24, 0, 0, 0))).format()
          taskDateTo: moment
            .utc(new Date(new Date().setHours(0, 0, 0)))
            .format(),
        };
        this.getTodayPendingTaskAuto(onloadParam);
      } else if (taskStatus == "upcoming") {
        let onloadParam = {
          taskDateFrom: moment
            .utc(new Date(new Date().setHours(23, 59, 59, 999)))
            .format(),
        };
        this.getTodayPendingTaskAuto(onloadParam);
      } else if (taskStatus == "today") {
        console.log(taskStatus)

        let onloadParam = {
          taskDateFrom: moment
            .utc(new Date(new Date().setHours(0, 0, 0)))
            .format(),
          taskDateTo: moment
            .utc(new Date(new Date().setHours(23, 59, 59, 999)))
            .format(),
        };
        this.getTodayPendingTaskAuto(onloadParam);
      } else {
        let onloadParam = {
          isCompleted: true,
        };
        this.getTodayPendingTaskAuto(onloadParam);
      }
    } else {
      console.log(task);
      if (task.lead) {
        // this.leadService.leadProfile(task.lead.lid);
        console.log("lead");
      } else if (task.deal) {
        console.log("deal");
        // this.leadService.dealProfile(task.deal['_id']);
      }
    }
    this.stopLoading();
  }

  public editPendingTask(
    forPage,
    status,
    id,
    pendingTaskId,
    data,
    leadNumberId?
  ) {
    console.log(forPage, status, data, leadNumberId);
    this.startLoading();
    return this.http
      .put(
        `${environment.API_URL}/pendingtask/lead/${id}/pendingTask/${pendingTaskId}/edit`,
        data
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            if (forPage == "taskPage") {
              this.checkPage(data, forPage, status);
            } else {
              if (leadNumberId && data.deal === undefined) {
                this.leadProfile(leadNumberId);
              } else if (data.deal) {
                this.dealProfile(data.deal);
              }
            }

            this.snackBar.openSnackBar(res["message"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  // public completePendingTask(pendingTaskId, data) {
  //   this.loader.loader = true;
  //   return this.http.patch(`${environment.API_URL}/pendingTask/${pendingTaskId}/complete`, data).pipe(
  //     map(res => res),
  //     tap(
  //       (res) => this.snackBar.openSnackBar(res['message']),
  //       (err) => this.snackBar.openSnackBar(err)
  //     ),
  //     finalize(() => this.loader.loader = false),
  //   ).subscribe();
  // }
  // dashboard
  public leadCount(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    // newParam.session = JSON.parse(localStorage.getItem("session"));
    this.http
      .get(`${environment.API_URL}/lead/dashboard`, { params: newParam })
      .pipe(
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      )
      .subscribe((res) => {
        // console.log(res,'dashboard count')
        this.leadCount$.next(res);
        this.store.dispatch({
          type: LEAD_COUNT,
          payload: res,
        });
      });
  }

  leadsCountClickOnTap(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    this.http
      .get(`${environment.API_URL}/lead/getleadsCount`, { params: newParam })
      .pipe(
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      )
      .subscribe((res) => {
        this.leadCount$.next(res);
      });
  }

  public leadCountSimple(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/lead/dashboard`, { params: newParam })
      .pipe(
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      );
  }

  public leadStatus() {
    this.startLoading();
    return this.http.get(`${environment.API_URL}/lead/outcome/list/all`).pipe(
      map(this.response.extractData),
      finalize(() => this.stopLoading())
    );
  }

  public editDeal(dealId, data, leadId?) {
    this.startLoading();
    return this.http
      .patch(
        `${environment.API_URL}/deal/${dealId}/edit`,
        pickBy(data, identity)
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            if (leadId) {
              console.log("update lead");
              this.leadProfile(leadId);
            }
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      );
  }

  public getOutcomes() {
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/interaction/outcome/list/all`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.stopLoading())
      );
  }

  // deal
  public addDeal(id, data, val?) {
    this.startLoading();
    data.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .post(
        `${environment.API_URL}/deal/lead/${id}/create`,
        pickBy(data, identity)
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            if (val == "no") {
            } else {
              this.snackBar.openSnackBar(res["message"]);
            }
            this.stopLoading();
            this.getDeals();
          },
          (err) => {
            this.snackBar.openSnackBar(err);
            this.stopLoading();
          }
        ),
        finalize(() => this.stopLoading())
      );
  }

  public getDeals(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    if (!newParam.lostReason) {
      newParam.isVerified = "Verified and interested";
    }
    return this.http
      .get(`${environment.API_URL}/deal`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        tap((val) => {
          this.allDeals$.next(val);
          // if (dialog) {
          //   dialog.close({
          //     val: 'filter',
          //     data: filterData,
          //     filterMainData: filterMainData
          //   })
          // }
        }),
        finalize(() => this.stopLoading())
      )
      .subscribe(res=>{
        this.getDealCount(params)
      });
  }

  public getDealCount(params?) {
    this.startLoading();
    let newParam = params ? params : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    if (!newParam.lostReason) {
      newParam.isVerified = "Verified and interested";
    }
    return this.http
      .get(`${environment.API_URL}/deal/getDeal/count`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        tap((val) => {
          this.allDealsCount$.next(val);
          // if (dialog) {
          //   dialog.close({
          //     val: 'filter',
          //     data: filterData,
          //     filterMainData: filterMainData
          //   })
          // }
        }),
        finalize(() => this.stopLoading())
      )
      .subscribe(res=>{
        
      })
  }

  public closeDeal(dealId, data, dialog, val?) {
    // console.log('data', data)
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/deal/${dealId}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            if (val) {
              this.addInteraction(val.lead, val).subscribe((res) => { });
            }
            if (dialog) {
              dialog.close();
            }
          },
          (err) => {
            this.snackBar.openSnackBar(err);
          }
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public deleteDeal(dealId, dialog?) {
    this.startLoading();
    return this.http
      .delete(`${environment.API_URL}/deal/${dealId}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            if (dialog) {
              dialog.close();
            }
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }
  // /list/all
  public dealProfile(dId) {
    //  console.log(dId);
    this.startLoading();
    return this.http
      .get(`${environment.API_URL}/deal/${dId}`, {
        params: { session: JSON.parse(localStorage.getItem("session")) },
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map(this.response.extractData),
        tap((res) => this.dealData$.next(res)),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  // /list/all
  public getLeadDeals(param) {
    this.startLoading();
    return this.http.get(`${environment.API_URL}/deal`, { params: param }).pipe(
      shareReplay(),
      takeLast(1),
      map((res) => res["data"]),
      shareReplay(),
      finalize(() => this.stopLoading())
    );
  }

  public markDuplicate(id, data, dialog) {
    this.startLoading();
    return this.http
      .post(
        `${environment.API_URL}/duplicateLead/lead/${id}/mark-duplicate`,
        data
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close();
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public getDuplicateLeads(param?) {
    this.startLoading();
    let newParam = param ? param : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/duplicateLead/list/all-duplicate`, {
        params: newParam,
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res["data"]),
        shareReplay(),
        finalize(() => this.stopLoading())
      );
  }

  public checkDuplicate(id, param?) {
    this.startLoading();
    let newParam = param ? param : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/duplicateLead/${id}`, { params: newParam })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res["data"]),
        shareReplay(),
        finalize(() => this.stopLoading())
      );
  }

  public mergeLead(data, dialog) {
    this.startLoading();
    return this.http
      .put(`${environment.API_URL}/duplicateLead/merge`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            this.router.navigate(["/leads"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public addPayout(id, data, dialog) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/deal/${id}/add-payout`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public deletePhone(id, data, dialog) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/delete-phone`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public deleteEmail(id, data, dialog) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/delete-email`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public editEmail(id, data, dialog) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/edit-email`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  public editPhone(id, data, dialog) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/lead/${id}/edit-phone`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.stopLoading())
      )
      .subscribe();
  }

  reduxData() {
    return this.store.select("appReducers");
  }
  leadReduxData() {
    return this.store.select("leadReducers");
  }

  public reOpen(data, id) {
    this.startLoading();
    return this.http
      .patch(`${environment.API_URL}/pendingtask/${id}/reOpen`, data)
      .pipe(finalize(() => this.stopLoading()));
  }

  public lastInteractions(param?) {
    let newParam = param ? param : {};
    newParam.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/interaction/lastInteraction`, {
        params: newParam,
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res["data"]),
        shareReplay()
      );
  }

  sendSimpleMail(data) {
    return this.http.post(`${environment.API_URL}/user/sendMail`, data);
  }
  callConnectToLeadMobileNumber(lid, data) {
    return this.http.post(`${environment.API_URL}/exotel/${lid}`, data);
  }
  getcallerIdData(id) {
    return this.http.get(`${environment.API_URL}/exotel/${id}`);
  }
  unsubsribeData(email) {
    return this.http.put(
      `${environment.API_URL}/contacts/unsubscribe/${email}`,
      null
    );
  }
  showFilters(data) {
    var applyData = [];
    for (var key in data) {
      if (key == "source") {
        for (let source in data["source"]) {
          let singleFilter = {
            key: key,
            value: data["source"][source]._id,
            label: data["source"][source].name,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "salesPerson") {
        for (let sales in data["salesPerson"]) {
          let singleFilter = {
            key: key,
            value: data["salesPerson"][sales]._id,
            label: data["salesPerson"][sales].name,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "user") {
        for (let sales in data["user"]) {
          let singleFilter = {
            key: key,
            value: data["user"][sales]._id,
            label: data["user"][sales].name,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "product") {
        for (let product in data["product"]) {
          let singleFilter = {
            key: key,
            value: data["product"][product]._id,
            label: data["product"][product].name,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "pipeline") {
        for (let pipeline in data["pipeline"]) {
          let singleFilter = {
            key: key,
            value: data["pipeline"][pipeline]._id,
            label: data["pipeline"][pipeline].stageName,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "discount") {
        for (let discount in data["discount"]) {
          let singleFilter = {
            key: key,
            value: data["discount"][discount]._id,
            label: data["discount"][discount].name,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "lead") {
        for (let lead in data["lead"]) {
          let singleFilter = {
            key: key,
            value: data["lead"][lead]._id,
            label: data["lead"][lead].name,
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "status") {
        for (let status in data["status"]) {
          let singleFilter = {
            key: key,
            value: data["status"][status],
            label: data["status"][status],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "country") {
        for (let country in data["country"]) {
          let singleFilter = {
            key: key,
            value: data["country"][country],
            label: data["country"][country],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "state") {
        for (let state in data["state"]) {
          let singleFilter = {
            key: key,
            value: data["state"][state],
            label: data["state"][state],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "city") {
        for (let city in data["city"]) {
          let singleFilter = {
            key: key,
            value: data["city"][city],
            label: data["city"][city],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "isVerified") {
        for (let isVerified in data["isVerified"]) {
          let singleFilter = {
            key: key,
            value: data["isVerified"][isVerified],
            label: data["isVerified"][isVerified],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "minAmount") {
        for (let minAmount in data["minAmount"]) {
          let singleFilter = {
            key: key,
            value: data["minAmount"][minAmount],
            label: data["minAmount"][minAmount],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else if (key == "maxAmount") {
        for (let maxAmount in data["maxAmount"]) {
          let singleFilter = {
            key: key,
            value: data["maxAmount"][maxAmount],
            label: data["maxAmount"][maxAmount],
            placeholder: key.charAt(0).toUpperCase() + key.slice(1),
          };
          applyData.push(singleFilter);
        }
      } else {
        let singleFilter = {
          key: key,
          value: data[key],
          label: data[key],
          placeholder: key.charAt(0).toUpperCase() + key.slice(1),
        };
        applyData.push(singleFilter);
      }
    }
    return applyData;
  }

  /**
   * Verify Email Contacts
   */
  public verifyEmailContacts(data, lid?) {
    this.startLoading();
    return this.http.post(`${environment.API_URL}/lists/verifylist/${lid}`, null).pipe(
      map((res) => res),
      tap(
        (res) => {

        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.stopLoading())
    );
  }

  


  /**
   * Fetch List Count
   */
  public fetchListCount(lid) {
    this.startLoading();
    return this.http.get(`${environment.API_URL}/lists/count/${lid}`).pipe(
      map((res) => res["data"]),
      shareReplay(),
      finalize(() => this.stopLoading())
    );
  }

  /**
   * Fetch List Count
   */
  public downloadReport(lid) {
    this.startLoading();
    return this.http.get(`${environment.API_URL}/list/report/${lid}`).pipe(
      map((res) => res["data"]),
      shareReplay(),
      finalize(() => this.stopLoading())
    );
  }

  public fetchClients() {
    this.startLoading();
    return this.http.get(`${environment.API_URL}/internal-clients/all`).pipe(
      map((res) => res["data"]),
      shareReplay(),
      finalize(() => this.stopLoading())
    );
  }

  public forwardToClients(data) {
    this.startLoading();
    return this.http.post(`${environment.API_URL}/internal-clients/send-lead`,data).pipe(
      map((res) => res),
      shareReplay(),
      finalize(() => this.stopLoading())
    );
  }

}
