// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // API_URL: "https://igtc.qnvert.com/api",
  // API_URL: "https://qnvertapi.kilobyte.live/api",
    // API_URL: "https://b2b.qnvert.com/api",
    // API_URL: "https://srhr.qnvert.com/api",
    
    // API_URL: "https://demosrhr.qnvert.com/api",
  API_URL: "/api",
  // API_URL: "https://thopstech.qnvert.com/api",
  // API_URL: "https://oakbridge.qnvert.com/api",
  // API_URL: "https://iptse.qnvert.com/api",
  // API_URL: "https://6414de3599ea.ngrok.io/api",
  // API_URL: "https://clients.qnvert.com/api",
  // API_URL: "https://ssbenterprises.qnvert.com/api",
  // API_URL: "https://forenthics.qnvert.com/api",
  // API_URL: "https://lawyered.qnvert.com/api",
  // API_URL: "https://myrecharge.qnvert.com/api",
  // API_URL: "https://sanroncorp.qnvert.com/api",
  // API_URL: "https://signup.qnvert.com/api",
  //  API_URL: 'https://b2c.qnvert.com/api',
  // API_URL: "/api",
  // API_URL: 'https://sothebysapi.qnvert.com'
  // API_URL: 'https://rietapi.qnvert.com'
  // API_URL:'https://cd2305f0a1bb.ngrok.io/api',
  // API_URL: 'http://localhost:5000/api',
  // API_URL: 'https://rupyaapaisa.qnvert.com/api',
  whatsappAppId: "8110279139005638",
  facebookAppId: "486657005676488",
};
// http://13.127.125.29:5000
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
//  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.